import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Layout from '../components/layout_page';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <Grid>
        <Row>
          <Col xs>
            <h4>{frontmatter.title}</h4>
            <h6>
              Last Updated:
              {frontmatter.date}
            </h6>
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
