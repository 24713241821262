import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
// import 'bootstrap/dist/css/bootstrap.css';

import Header from './header';
import Footer from './footer';
import './layout.css';

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
      query PageTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }`}
      render={(data) => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'AEP Claims Portal' },
              { name: 'keywords', content: 'damage, claims, aep, american electric power' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            style={{
              margin: '0 auto',
              maxWidth: '100%',
              padding: '0px 1.0875rem 1.45rem',
              paddingTop: 0,
            }}
          >
            {children}
          </div>
          <Footer />
        </>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
